import StorerInventoryModel from 'src/@types/models/StorerInventoryModel';
import { getModel, getModels } from './base';

export const getStoredItems = async (): Promise<StorerInventoryModel[] | null> => {
  const res: any = await getModels(`/stores/inventory`);
  // console.log(res.data, 'RESPONSE');
  return res.data as StorerInventoryModel[];
};

export const getStoredItem = async (id: string): Promise<StorerInventoryModel[] | null> => {
  const res: any = await getModels(`/stores/inventory/${id}`);
  // console.log(res.data, 'RESPONSE');
  return res.data as StorerInventoryModel[];
};
