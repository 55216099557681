// @mui
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
// Components
import Image from './Image';

type Props = {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  token: string;
  chainId: number | null | undefined;
  address: string | null | undefined;
  closePopup: () => void;
};

const getTokenIcon = (token: string) => {
  const tokenUrl: Record<string, string> = {
    usdc: '/icons/ic_usdc.svg',
    usdt: '/icons/ic_usdt.svg',
  };
  return <Image src={tokenUrl[token]} sx={{ height: 52 }} />;
};

const CryptoTransferPopover = ({
  open,
  anchorEl,
  token,
  chainId,
  address,
  closePopup,
}: Props): React.ReactElement => (
  <Popover
    open={open}
    onClose={closePopup}
    anchorEl={anchorEl}
    marginThreshold={48}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    PaperProps={{
      style: { maxWidth: '80%', maxHeight: '80%', border: '1px solid #00000043' },
    }}
  >
    <Card sx={{ height: '100%', minWidth: 520 }}>
      <CardHeader
        title="Transferring stablecoin"
        subheader="Recyclium is covering gas fees"
        avatar={getTokenIcon(token)}
        action={
          <IconButton aria-label="close" onClick={closePopup}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <TextField
          fullWidth={true}
          margin="normal"
          size="medium"
          variant="outlined"
          label="Wallet Address"
          value={address}
          disabled
        />
        <LinearProgress />
      </CardContent>
    </Card>
  </Popover>
);

export default CryptoTransferPopover;
