// @mui
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';

type Props = {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  paymentMethod: string;
  closePopup: () => void;
};

const BankTransferPopover = ({ open, anchorEl, closePopup }: Props): React.ReactElement => (
  <Popover
    open={open}
    onClose={closePopup}
    anchorEl={anchorEl}
    marginThreshold={48}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    PaperProps={{
      style: { maxWidth: '80%', maxHeight: '80%', border: '1px solid #00000043' },
    }}
  >
    <Card sx={{ height: '100%', minWidth: 520 }}>
      <CardHeader
        title="Transferring"
        action={
          <IconButton aria-label="close" onClick={closePopup}>
            <CloseIcon />
          </IconButton>
        }
      />
      <CardContent>
        <TextField
          fullWidth={true}
          margin="normal"
          size="medium"
          variant="outlined"
          label="Bank name"
          value="HSBC Holdings plc"
          disabled
        />
        <TextField
          fullWidth={true}
          margin="normal"
          size="medium"
          variant="outlined"
          label="IBAN"
          value="GB82 WEST 1234 5698 7654 32"
          disabled
        />
        <LinearProgress />
      </CardContent>
    </Card>
  </Popover>
);

export default BankTransferPopover;
