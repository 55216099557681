import { createContext } from 'react';
import { CredentialResponse } from '@react-oauth/google';
import UserModel from '../@types/models/UserModel';

export interface UserContextType {
  userModel?: UserModel | null;
  googleSignInSuccess?: ((credentialResponse: CredentialResponse) => void) | null;
  token?: string | null;
  setUserModel?: (userModel: UserModel | null) => void;
  setToken?: (token: string | null) => void;
}

const UserContext = createContext<UserContextType>({
  userModel: null,
  googleSignInSuccess: null,
  token: null,
  setUserModel: () => {},
  setToken: () => {},
});

export default UserContext;
