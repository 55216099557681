// @ts-nocheck
import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => (
  <>
    <Helmet>
      <title>{`${title} | Recyclium Admin`}</title>
      {meta}
    </Helmet>
    {/* height: '100%',  */}
    <Box ref={ref} {...other} sx={{ paddingBottom: 3 }}>
      {children}
    </Box>
  </>
));

export default Page;
