import React, { ReactNode, useState, useEffect } from 'react';
import UserContext, { UserContextType } from './UserContext';
import UserModel from 'src/@types/models/UserModel';
import { CredentialResponse } from '@react-oauth/google';

interface UserProviderProps {
  children: ReactNode;
  initialUserModel?: UserModel | null;
  initialSetUserModel?: (user: UserModel | null) => void;
  googleSignInSuccess?: (credentialResponse: CredentialResponse) => void;
}

const UserProvider: React.FC<UserProviderProps> = ({
  children,
  initialUserModel,
  initialSetUserModel,
  googleSignInSuccess,
}) => {
  const [userModel, setUserModel] = useState<UserModel | null>(initialUserModel || null);
  const [token, setToken] = useState<string | null>(null);
  // Use the provided setUserModel if available
  useEffect(() => {
    if (initialSetUserModel) {
      initialSetUserModel(userModel);
    }
  }, [userModel, initialSetUserModel]);

  return (
    <UserContext.Provider value={{ userModel, setUserModel, token, setToken, googleSignInSuccess }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
