//@ts-nocheck
import { useState, useEffect } from 'react';
// Libs
import { SnackbarProvider, useSnackbar } from 'notistack';
import { GoogleOAuthProvider, CredentialResponse } from '@react-oauth/google';
// @mui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import ThemeColorPresets from './components/ThemeColorPresets';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// Context
import UserContext from './contexts/UserContext';
// Models
import UserModel from './@types/models/UserModel';
// API
import { googleAuthenticate } from './api/base';
import { apiGetCurrentUser, getUserById } from './api/user';
// Utils
import { getAccessTokenCookieData } from './utils/cookie';
// routes
import Router from './routes';
import UserProvider from './contexts/UserProvider';
// ----------------------------------------------------------------------

const googleClientId = '293410041947-offdbp7eaj558ol7g4ttbdi8t5arv1b8.apps.googleusercontent.com';

// ----------------------------------------------------------------------

export default function App() {
  const { enqueueSnackbar } = useSnackbar();
  const [userModel, setUserModel] = useState<UserModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getUser = async (id: string) => {
    setLoading(true);

    const user = await getUserById(id);

    if (user.id !== 'undefined') {
      setUserModel(user);
      setLoading(false);
    } else {
      setUserModel(null);
    }
  };

  const googleSignInSuccess = (credentialResponse: CredentialResponse): void => {
    googleAuthenticate(credentialResponse).then(async (res) => getUser(res.jwtToken));
  };

  useEffect(() => {
    const fetchUserData = async () => {
      // get the authToken from the browser
      const authToken: any = getAccessTokenCookieData();

      if (authToken) {
        // fetch from the api
        await getUser(authToken);
      }
    };

    fetchUserData().catch((err) => console.log(err));
  }, []);

  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <RtlLayout>
          <MotionLazyContainer>
            <ProgressBarStyle />
            <Settings />
            <ScrollToTop />
            <SnackbarProvider autoHideDuration={5500} maxSnack={3}>
              <GoogleOAuthProvider key={userModel?.id} clientId={googleClientId}>
                <UserProvider
                  initialUserModel={userModel}
                  googleSignInSuccess={googleSignInSuccess}
                >
                  <Router userModel={userModel} />
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </UserProvider>
              </GoogleOAuthProvider>
            </SnackbarProvider>
          </MotionLazyContainer>
        </RtlLayout>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
