import { ReactNode } from 'react';
import isString from 'lodash/isString';
// @mui
import { Box, Typography, Link, useMediaQuery } from '@mui/material';
import Breadcrumbs, { Props as BreadcrumbsProps } from './Breadcrumbs';

// ----------------------------------------------------------------------

interface Props extends BreadcrumbsProps {
  action?: ReactNode;
  heading?: ReactNode;
  moreLink?: string | string[];
}

export default function HeaderBreadcrumbs({
  links,
  action,
  heading,
  moreLink = '' || [],
  sx,
  ...other
}: Props) {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          alignItems: isMobile ? 'center' : 'flex-start',
        }}
      >
        <Box sx={{ flexGrow: 1, textAlign: isMobile ? 'center' : 'left' }}>
          {typeof heading === 'string' ? (
            <Typography variant="h4" gutterBottom>
              {heading}
            </Typography>
          ) : (
            <>{heading}</>
          )}
          <Breadcrumbs links={links} {...other} />
        </Box>

        {action && (
          <Box
            sx={{
              flexShrink: 0,
              marginTop: isMobile ? 2 : 0,
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: 'center',
              gap: isMobile ? 1 : 0,
            }}
          >
            {action}
          </Box>
        )}
      </Box>

      <Box sx={{ mt: 2 }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" rel="noopener" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>
    </Box>
  );
}
