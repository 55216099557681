import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { Container, Grid, Card, CardContent } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// Icons
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
// hooks
import useSettings from '../hooks/useSettings';
// components
import Page from '../components/Page';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Reward from '../components/Reward';
import StorerInventoryModel from 'src/@types/models/StorerInventoryModel';
import { getStoredItems, getStoredItem } from 'src/api/inventory';
import { QRCode } from 'react-qrcode-logo';
import { useLocation, useParams } from 'react-router';
import { QRScanner } from '../components/scanner/QrScanner';

export default function InventoryConfirmed() {
  const { themeStretch } = useSettings();
  // State.
  const [openRewardDetails, setOpenRewardDetails] = useState<null | string>(null);
  const [inventory, setInventory] = useState<StorerInventoryModel[] | null>(null);
  const [confirmationQRCode, setConfirmationQRCode] = useState<string | null>(null);
  const queryParameters = new URLSearchParams(useLocation().search);
  const missionId: any = queryParameters.get('missionId');
  const [isOpenCamera, setIsOpenCamera] = useState<boolean>(false);

  const formatDateTime = (dateString: any) => {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString();

    return formattedDate;
  };

  const handleScanner = () => {
    if (isOpenCamera) {
      setIsOpenCamera(false);
    } else {
      setIsOpenCamera(true);
    }
  };

  const getData = async () => {
    try {
      if (missionId) {
        const inventory: StorerInventoryModel[] | null = await getStoredItem(missionId);
        if (inventory) {
          setInventory(inventory);
        }
      } else {
        // fetch the data from the api
        const inventory: StorerInventoryModel[] | null = await getStoredItems();
        if (inventory) {
          setInventory(inventory);
        }
      }
    } catch {
      console.log('error getting stored items');
    }
  };

  useEffect(() => {
    const getAll = async () => {
      await getData();
    };

    getAll().catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!!inventory?.length) {
      setConfirmationQRCode(
        `https://enterprise.recyclium.io/waste_management/confirm/receipt?receipts=${inventory
          .map((inventoryItem) => inventoryItem.receipt_id)
          .join(',')}`,
      );
    }
  }, [inventory, confirmationQRCode]);

  return (
    <Page title="Confirm Collection">
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <HeaderBreadcrumbs
          heading="Confirm Collection"
          action={
            <Button
              variant="contained"
              onClick={() => handleScanner()}
              startIcon={<QrCodeScannerIcon />}
            >
              {!isOpenCamera ? 'Scan Collector QR Code and confirm' : 'Close Scanner'}
            </Button>
          }
        />
        {isOpenCamera && <QRScanner />}
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Mission</TableCell>
                    <TableCell align="right">Items</TableCell>
                    <TableCell align="right">Claim</TableCell>
                    <TableCell align="right">Collected At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(inventory || []).map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Button
                          fullWidth={true}
                          variant="outlined"
                          onClick={() => setOpenRewardDetails(row.id)}
                        >
                          {row.title}
                        </Button>
                      </TableCell>
                      <TableCell align="right">{row.total_amount}</TableCell>
                      <TableCell align="right">
                        ${Math.round((row.total_amount * 0, 6) * 100) / 100}
                      </TableCell>
                      <TableCell align="right">{formatDateTime(row?.updatedAt.value)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent className="qr-border">
                {confirmationQRCode && (
                  <QRCode
                    style={{
                      width: '100%',
                    }}
                    ecLevel="Q"
                    quietZone={0}
                    size={240}
                    // logoImage="/logo/recyclium-logo.png"
                    removeQrCodeBehindLogo={true}
                    value={confirmationQRCode}
                    eyeColor={[
                      '#1bb0ac', // top/left eye
                      '#91cfdf', // top/right eye
                      '#316294', // bottom/left eye
                    ]}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <SwipeableDrawer
        anchor="right"
        open={Boolean(openRewardDetails)}
        onClose={() => setOpenRewardDetails(null)}
        onOpen={() => {}}
        PaperProps={{ style: { width: '71%', minWidth: 320 } }}
      >
        <Reward />
      </SwipeableDrawer>
    </Page>
  );
}
