import QrScanner from 'qr-scanner';
import { useEffect, useRef, useState } from 'react';
import { Autocomplete, Box, Card, CardHeader, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';

interface ScannerProps {
  qrCodeType?: number | null;
  flavours?: string[] | null;
}

export const QRScanner = (props: ScannerProps) => {
  const videoElementRef = useRef(null);
  const [scanned, setScannedText] = useState('');
  const [pouchItems, setPouchItems] = useState<string>('');
  const [selectedFlavours, setSelectedFlavours] = useState<string>('');

  useEffect(() => {
    const video: HTMLVideoElement | any = videoElementRef.current;
    const qrScanner = new QrScanner(
      video,
      (result) => {
        setScannedText(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      },
    );
    qrScanner.start();

    return () => {
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);

  return (
    <Grid
      container
      spacing={0}
      sx={{
        minHeight: '90vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#f0f2f5',
      }}
    >
      <Grid item xs={12} md={6} lg={4}>
        <Card
          sx={{
            padding: 3,
            borderRadius: 3,
            boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
            textAlign: 'center',
            backgroundColor: '#ffffff',
          }}
        >
          <CardHeader
            title={scanned ? 'SCANNING COMPLETE' : 'SCANNING QRCODE'}
            subheader={
              scanned ? 'Click the continue button' : 'Please wait for scanning to complete'
            }
            sx={{
              paddingBottom: 2,
              '& .MuiCardHeader-title': {
                fontSize: '1.5rem',
                fontWeight: 700,
                color: '#1976d2',
              },
              '& .MuiCardHeader-subheader': {
                fontSize: '1rem',
                color: '#555555',
              },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2,
            }}
          >
            <Box
              sx={{
                width: '256px',
                height: '256px',
                borderRadius: '12px',
                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
                overflow: 'hidden',
                marginBottom: 2,
                backgroundColor: '#e0e0e0',
              }}
            >
              <video
                ref={videoElementRef}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>

            {scanned && props.qrCodeType && props.qrCodeType === 2 && (
              <Grid item xs={12} md={10} sx={{ marginTop: 2 }}>
                <TextField
                  autoComplete="off"
                  fullWidth={true}
                  variant="outlined"
                  type="number"
                  label="Number of items"
                  placeholder="Enter number"
                  helperText="Enter the number of items in the pouch"
                  value={pouchItems}
                  onChange={(event) => {
                    const { value } = event.target;
                    setPouchItems(value === '' ? '' : String(Number(value)));
                  }}
                  InputProps={{
                    inputProps: {
                      min: 0,
                      step: 1,
                    },
                    sx: {
                      padding: '6px 10px',
                      fontSize: '1rem',
                      borderRadius: '12px',
                      backgroundColor: '#f5f5f5',
                      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        backgroundColor: '#eaeaea',
                      },
                      '&.Mui-focused': {
                        borderColor: '#1976d2',
                        boxShadow: '0px 0px 0px 2px rgba(25, 118, 210, 0.2)',
                      },
                    },
                  }}
                  sx={{
                    marginTop: 2,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      backgroundColor: '#f5f5f5',
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '1rem',
                      color: '#1976d2',
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: '0.875rem',
                      color: '#555555',
                    },
                  }}
                />
              </Grid>
            )}
            {scanned && (
              <Grid item xs={12} md={10} sx={{ marginTop: 2 }}>
                <Autocomplete
                  options={props.flavours ?? []}
                  value={selectedFlavours}
                  onChange={(event, newValue) => {
                    setSelectedFlavours(newValue as string);
                  }}
                  sx={{
                    minWidth: '16rem',
                    marginTop: 2,
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                      backgroundColor: '#f5f5f5',
                    },
                    '& .MuiInputLabel-root': {
                      fontSize: '1rem',
                      color: '#1976d2',
                    },
                    '& .MuiFormHelperText-root': {
                      fontSize: '0.875rem',
                      color: '#555555',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Flavors"
                      placeholder="Type and press enter"
                    />
                  )}
                />
              </Grid>
            )}

            {scanned && (
              <Button
                variant="contained"
                href={
                  pouchItems
                    ? `${scanned}&pouchItems=${pouchItems}&flavour=${selectedFlavours}`
                    : `${scanned}`
                }
                target="_blank"
                disabled={props.qrCodeType === 2 && (!pouchItems || pouchItems === '0')}
                sx={{
                  marginTop: 3,
                  padding: '10px 20px',
                  fontSize: '1rem',
                  borderRadius: '12px',
                  backgroundColor: '#1976d2',
                  color: '#fff',
                  boxShadow: '0px 8px 20px rgba(25, 118, 210, 0.4)',
                  '&:hover': {
                    backgroundColor: '#115293',
                  },
                }}
              >
                Continue
              </Button>
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};
