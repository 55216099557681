import React, { useState, ChangeEvent, FormEvent, useContext } from 'react';
import {
  TextField,
  FormGroup,
  Button,
  Box,
  Typography,
  Paper,
  Divider,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Logo from 'src/components/Logo';
import { apiLogin } from 'src/api/login';
import UserModel from 'src/@types/models/UserModel';
import UserContext, { UserContextType } from 'src/contexts/UserContext';
import SignInGoogleButton from 'src/components/SignInGoogleButton';
import Iconify from 'src/components/Iconify';

const MyForm: React.FC = () => {
  const navigate = useNavigate();
  const { setUserModel, googleSignInSuccess } = useContext<UserContextType>(UserContext);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [touchedFields, setTouchedFields] = useState({
    email: false,
    password: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setTouchedFields((prevData) => ({
      ...prevData,
      [name]: true,
    }));
  };

  const validate = () => {
    let tempErrors = { email: '', password: '' };
    tempErrors.email = formData.email ? '' : 'Email is required';
    tempErrors.password = formData.password ? '' : 'Password is required';
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate()) {
      try {
        const result = await apiLogin(formData);
        if (result && result.data) {
          const userData = result.data.data as UserModel;
          if (setUserModel) {
            setUserModel(userData);
            localStorage.setItem('id', userData.id);
          }
          setSnackbarMessage('Login successful!');
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
          navigate('/');
        } else {
          setSnackbarMessage('Login result is not valid');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        }
      } catch (error) {
        setSnackbarMessage('Login failed');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        console.error('Login failed', error);
      }
    } else {
      setSnackbarMessage('Please fill all the required fields');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const showError = (field: keyof typeof formData) => touchedFields[field] && !formData[field];

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: '#f5f5f5',
        padding: 2,
      }}
    >
      <Paper elevation={6} sx={{ padding: 4, maxWidth: 600, width: '100%', borderRadius: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <Logo sx={{ width: 150 }} />
        </Box>
        <Typography variant="h4" component="h1" gutterBottom textAlign="center">
          Welcome Back!
        </Typography>
        <Typography variant="body1" color="textSecondary" textAlign="center" mb={2}>
          Please sign in to your account
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
          }}
        >
          <FormGroup>
            <TextField
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              error={showError('email')}
              helperText={showError('email') && 'Email is required'}
              InputProps={{
                startAdornment: (
                  <Iconify icon="eva:email-outline" sx={{ color: 'text.secondary', mr: 1 }} />
                ),
              }}
            />
            <TextField
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              fullWidth
              required
              error={showError('password')}
              helperText={showError('password') && 'Password is required'}
              InputProps={{
                startAdornment: (
                  <Iconify icon="eva:lock-outline" sx={{ color: 'text.secondary', mr: 1 }} />
                ),
              }}
            />
          </FormGroup>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Sign In
          </Button>
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {googleSignInSuccess && (
              <>
                <Divider sx={{ width: '100%', mb: 2 }}>OR</Divider>
                <SignInGoogleButton googleSignInSuccess={googleSignInSuccess} useOneTap={true} />
              </>
            )}
          </Box>
          <Typography variant="body2" color="textSecondary" sx={{ textAlign: 'center', mt: 2 }}>
            Don't have an account?{' '}
            <Button color="primary" onClick={() => navigate('/auth/register')}>
              Register
            </Button>
          </Typography>
        </Box>
      </Paper>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MyForm;
