import { useState } from 'react';
// Lib
import { useWeb3ModalAccount, useWeb3Modal } from '@web3modal/ethers5/react';
// @mui
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
// Icons
import LoginIcon from '@mui/icons-material/Login';
// Components
import Image from './Image';
import TransferPopover from './BankTransferPopover';
import CryptoTransferPopover from './CryptoTransferPopover';
// Models
// import StorerModel from '../@types/models/StorerModel';

import { initWeb3Modal } from '../utils/crypto';
// Reward can be made by crypto.
initWeb3Modal();

function createData(serialNumber: string, material: string = 'aluminium') {
  return { serialNumber, material, icon: '/icons/ic_aluminium.svg' };
}

const rows = [
  createData('2211223'),
  createData('4332356'),
  createData('6643568'),
  createData('4335677'),
  createData('4343432'),
];

type Props = {
  detailed?: boolean;
};

type StepType = {
  label: string;
  date?: string;
  name?: string;
};

const steps: Array<StepType> = [
  {
    label: 'Storer',
    date: '12 December 2023',
    name: 'Boardwalk Place Shop',
  },
  {
    label: 'Recycler',
    date: '14 December 2023',
    name: 'W Recycling LTD',
  },
  {
    label: 'Recycled',
  },
];

type TransferType = {
  id: string;
  label: string;
  icon?: any;
};

const selectOptions: Array<TransferType> = [
  {
    id: 'bank',
    label: 'Bank transfer',
    icon: '/icons/ic_banking.svg',
  },
  {
    id: 'plp',
    label: 'Paypal',
    icon: '/icons/ic_paypal.svg',
  },
  {
    id: 'wise',
    label: 'Wise',
    icon: '/icons/ic_wise.svg',
  },
  {
    id: 'usdc',
    label: 'USDC',
    icon: '/icons/ic_usdc.svg',
  },
  {
    id: 'usdt',
    label: 'USDT',
    icon: '/icons/ic_usdt.svg',
  },
];

export default function Reward({ detailed = true }: Props) {
  // Web3 hooks.
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const web3Modal = useWeb3Modal();
  //state
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<string>('bank');

  const claimButton = () => {
    if (['usdc', 'usdt'].includes(paymentMethod)) {
      return isConnected ? (
        <Button
          size="large"
          variant="contained"
          fullWidth={true}
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setPopoverAnchorEl(event.currentTarget);
          }}
        >
          Claim gas free reward
        </Button>
      ) : (
        <Button
          size="large"
          variant="contained"
          fullWidth={true}
          onClick={() => web3Modal.open()}
          startIcon={<LoginIcon />}
        >
          Connect Wallet
        </Button>
      );
    }
    return (
      <Button
        size="large"
        variant="contained"
        fullWidth={true}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setPopoverAnchorEl(event.currentTarget);
        }}
      >
        Request Transfer
      </Button>
    );
  };

  return (
    <>
      <Grid container={true} spacing={3} sx={{ p: 3 }}>
        <Grid item xs={12} md={12}>
          <Card>
            <CardHeader
              title="$48 | Coca-Cola 300ml"
              subheader="12 December 2023"
              avatar={<img alt="-" width="60" src="/images/logo/cocacola.png" />}
              action={
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    border: '1px solid #efefef',
                    padding: 1,
                    borderRadius: 1,
                  }}
                >
                  {`Trace & Collect Rewards`}
                </Typography>
              }
            />
            <CardContent>
              <Stepper activeStep={1} alternativeLabel={true}>
                {steps.map((step: StepType, index: number) => (
                  <Step key={index}>
                    <StepLabel>
                      <Typography variant="subtitle2" component="div" paragraph={true}>
                        {step.label}
                      </Typography>
                      {step.name ? (
                        <Typography variant="subtitle1" component="div" paragraph={true}>
                          {step.name}
                        </Typography>
                      ) : (
                        <Chip size="medium" label="Pending" color="warning" />
                      )}
                      <Typography variant="caption" component="div">
                        {step.date}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Rewards per item"
                    id="rewards-per-item"
                    value={0.6}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Claimed items"
                    id="claimed-items"
                    value={80}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Image src="/icons/ic_can.svg" sx={{ width: 26, mr: 2 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Total"
                    id="claimed-total"
                    value={48}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">USD</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <CardContent>
              <Grid container={true} spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth={true}
                    label="Reward paid by"
                    id="paid-by"
                    select={true}
                    value={paymentMethod}
                    onChange={(event) => setPaymentMethod(event.target.value)}
                  >
                    {selectOptions?.map((optionItem: TransferType) => (
                      <MenuItem key={optionItem.id} value={optionItem.id}>
                        <Grid container spacing={1}>
                          <Grid item={true} xs={12} md={3}>
                            {typeof optionItem?.icon === 'string' ? (
                              <Image src={optionItem?.icon} sx={{ height: 26, mr: 2 }} />
                            ) : (
                              optionItem.icon
                            )}
                          </Grid>
                          <Grid item={true} xs={12} md={9}>
                            {optionItem.label}
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                  {claimButton()}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card>
            <img alt="-" width="100%" src="/images/beverage.jpeg" />
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <TableContainer component={Paper}>
            <Table aria-label="Recyclers table">
              <TableHead>
                <TableRow>
                  <TableCell>Item Confirmation QR Code</TableCell>
                  <TableCell align="right" />
                  <TableCell align="right">Material</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index: number) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {row.serialNumber}
                    </TableCell>
                    <TableCell align="left">
                      <Image src={row.icon} sx={{ width: 26, mr: 2 }} />
                    </TableCell>
                    <TableCell align="right">
                      <Chip size="medium" label={row.material} color="success" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {['usdc', 'usdt'].includes(paymentMethod) ? (
        <CryptoTransferPopover
          open={Boolean(popoverAnchorEl)}
          anchorEl={popoverAnchorEl}
          token={paymentMethod}
          chainId={chainId}
          address={address}
          closePopup={() => setPopoverAnchorEl(null)}
        />
      ) : (
        <TransferPopover
          open={Boolean(popoverAnchorEl)}
          anchorEl={popoverAnchorEl}
          paymentMethod={paymentMethod}
          closePopup={() => setPopoverAnchorEl(null)}
        />
      )}
    </>
  );
}
