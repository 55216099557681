// @mui icons.
import AddCardIcon from '@mui/icons-material/AddCard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RecyclingIcon from '@mui/icons-material/Recycling';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupIcon from '@mui/icons-material/Group';
import InventoryIcon from '@mui/icons-material/Inventory';
import WindPowerIcon from '@mui/icons-material/WindPower';
// components
// import SvgIconStyle from '../../../components/SvgIconStyle';
// type
import { NavSectionConfigProps } from '../../../components/nav-section/type';
// Models
import UserModel from '../../../@types/models/UserModel';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

const ICONS = {
  rewards: <AddCardIcon />,
  dashboard: <DashboardIcon />,
  missions: <Diversity3Icon />,
  storer: <WarehouseIcon />,
  recycling: <RecyclingIcon />,
  team: <GroupIcon />,
  inventory: <InventoryIcon />,
  esg: <WindPowerIcon />,
};

export const getNavConfig = (
  userModel: UserModel | null | undefined = null,
): Array<NavSectionConfigProps> => {
  if (userModel) {
    return [
      {
        subheader: 'CUSTOMER',
        items: [
          { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
          { title: 'Missions', path: '/missions', icon: ICONS.missions },
          { title: 'Rewards', path: '/rewards', icon: ICONS.rewards },
          { title: 'Storers', path: '/storers', icon: ICONS.storer },
        ],
      },
      {
        subheader: 'STORER',
        items: [
          // { title: 'Scan Collector Code', path: '/dashboard', icon: ICONS.scan },
          { title: 'Inventory', path: '/storer/inventory', icon: ICONS.inventory },
          { title: 'Recycled', path: '/storer/recycled', icon: ICONS.recycling },
          // { title: 'Team', path: '/storer/team', icon: ICONS.team },
        ],
      },
    ];
  }
  return [
    {
      subheader: 'CUSTOMER',
      items: [
        { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
        { title: 'Missions', path: '/missions', icon: ICONS.missions },
        { title: 'Storers', path: '/storers', icon: ICONS.storer },
      ],
    },
  ];
};
