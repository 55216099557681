import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// Layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// Components
import LoadingScreen from '../components/LoadingScreen';
// Models
import UserModel from '../@types/models/UserModel';
import InventoryConfirmed from 'src/pages/InventoryConfirmed';
import Login from 'src/pages/Login';
import Register from 'src/pages/Register';

// ----------------------------------------------------------------------

type Props = {
  userModel?: UserModel | null | undefined;
};

export default function Router({ userModel }: Props): React.ReactElement {
  const isLogged = Boolean(userModel?.isSigned);
  console.log('isLogged', isLogged);
  return (
    <>
      {useRoutes([
        {
          path: '/',
          element: <Navigate to="/dashboard" replace />,
        },
        {
          path: '/',
          element: <DashboardLayout />,
          children: [
            // { element: <Navigate to="/dashboard" replace />, index: false },
            // { element: <Dashboard /> },
            { path: 'dashboard', element: <Dashboard /> },
            { path: 'rewards', element: <Rewards /> },
            { path: 'mission/:missionId', element: <Mission /> },
            { path: 'mission/:missionId/confirm', element: <MissionConfirm /> },
            { path: 'mission/:missionId/confirm/receipt', element: <MissionConfirmReceipt /> },
            { path: 'missions', element: <Missions /> },
            { path: 'storers', element: <Storers /> },
          ],
        },
        {
          path: '/storer',
          element: <DashboardLayout />,
          children: [
            { path: 'inventory', element: <Inventory /> },
            { path: 'inventory/confirm', element: <InventoryConfirmed /> },
            { path: 'recycled', element: <Recycled /> },
            // { path: 'team', element: <Team /> },
          ],
        },
        {
          path: '/auth',
          children: [
            { path: 'login', element: <Login /> },
            { path: 'register', element: <Register /> },
          ],
        },
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" replace /> },
          ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
      ])}
    </>
  );
}

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// Customer.
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Mission = Loadable(lazy(() => import('../pages/Mission')));
const Missions = Loadable(lazy(() => import('../pages/Missions')));
const Rewards = Loadable(lazy(() => import('../pages/Rewards')));
const Storers = Loadable(lazy(() => import('../pages/Storers')));
const MissionConfirm = Loadable(lazy(() => import('../pages/MissionConfirm')));
const MissionConfirmReceipt = Loadable(lazy(() => import('../pages/MissionConfirmReceipt')));
// Storer
const Inventory = Loadable(lazy(() => import('../pages/Inventory')));
const Recycled = Loadable(lazy(() => import('../pages/Recycled')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
