// Materials.
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

type Props = {
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  closePopup: () => void;
};

const ContactPopover = ({ open, anchorEl, closePopup }: Props): React.ReactElement => (
  <Popover
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <Card sx={{ height: '100%', width: '100%' }}>
      <CardHeader
        title={''}
        action={
          <Button variant="outlined" size="small" color="primary" onClick={closePopup}>
            X
          </Button>
        }
      />
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography variant="body2" paragraph={true} sx={{ width: '300px' }}>
            Please contact with Dawid Planeta <a href="mailto:dawid@mail.com">dawid@mail.com</a>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  </Popover>
);

export default ContactPopover;
