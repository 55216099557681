// Utils
import axios from 'axios';
import { deleteAccessTokenCookie } from '../utils/cookie';
import getHost from 'src/utils/getHost';

export const apiLogout = () => {
  deleteAccessTokenCookie();
  localStorage.removeItem('id');
  window.location.href = '/';
};
export const apiRegister = async (body: any) => {
  const result = await axios.post(`${getHost()}/api/v1/auth/register`, body);
  console.log(result);
};
export const apiLogin = async (body: any) => {
  const result = await axios.post(`${getHost()}/api/v1/auth/login`, body, {
    withCredentials: true,
  });
  return result;
};
